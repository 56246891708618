@import "../../../../../themes/colors";
.collapseContent {
  position: relative;
  flex-direction: column;
  display: grid;

  &.open {
    border-bottom: 1px solid var(--banner-border-color);
  }
}

.collapseHeader {
  display: flex;
  padding-left: 16px;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;
  background: var(--form-element-background-color);
  line-height: 1;
  border-bottom: 2px solid var(--banner-border-color);

  &.nested {
    padding-left: calc(0.6rem * 3);
    padding-block: calc(0.8rem / 2);
  }
}

.fakeCollapseButtonArea {
  width: 24px;
  height: 24px;
}

.fakeCollapseButtonArea {
  width: 24px;
  height: 24px;
}