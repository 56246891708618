@import "../../../themes/colors";

.collapseButton {
  display: inline-flex;
  padding: 0 !important;
  min-height: 0 !important;
  min-width: 0 !important;
  height: fit-content;
  width: fit-content;
  align-self: center;
  box-shadow: none !important;
  margin-right: 0.5rem;
  gap: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;

  svg {
    width: 24px;
    height: 24px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    path {
      fill: #FFF
    }
  }

  &:hover {
    background: var(--banner-border-color) !important;
  }

  &.active {
    svg {
      transform: rotate(90deg);
    }
  }
}