@import "../../../../themes/colors";
.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .name {
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px 6px 0;

  .button {
    align-self: stretch;

    font-size: 0.65rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    .indicator {
      display: flex;
      position: absolute;
      width: 85%;
      top: 80%;
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }
  }
}
