@import "../../../themes/breakpoints";
@import "../../../themes/colors";

.dayIcon {
  @include less-1000 {
    max-width: 32px;
    height: 32px;
    font-size: 14px !important;
  }
  @include more-1000 {
    max-width: 40px;
    max-height: 40px;
    font-size: 16px !important;
  }
  @include for-small-phone {
    max-width: 30px;
    max-height: 30px;
    font-size: 14px !important;
  }

  border: 1px solid var(--banner-border-color) !important;

  &:hover {
    background-color: var(--banner-border-color) !important;
  }
}

.info {
  border-color: transparent !important;
  background-color: var(--day-icon-info-color) !important;

  &:hover {
    background-color: var(--day-icon-info-hover-color) !important;
  }
}

.warning {
  border-color: transparent !important;
  background-color: var(--day-icon-warning-color) !important;

  &:hover {
    background-color: var(--day-icon-warning-hover-color) !important;
  }
}