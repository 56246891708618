@import "../../../../themes/colors";

.containerTimeReportGroup {
  display: flex;
  width: 100%;

  .name {
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: inline-block;
    min-width: unset;
    max-width: unset;
    overflow: hidden;
    box-sizing: border-box;
    width: auto;
    flex-grow: 1;
    border-right: 1px solid var(--banner-border-color);
    align-content: space-around;
  }

  .info {
    display: flex;

    .hours {
      display: grid;
      grid-template-columns: minmax(88px, 1fr) minmax(88px, 1fr) minmax(88px, 1fr) minmax(88px, 1fr) minmax(88px, 1fr);
      justify-content: center;
      height: 100%;

      .cell {
        display: flex;
        width: 88px;
        flex-direction: column;
        justify-content: center;
        padding: 16.54px 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        text-align: right;

        &:not(:last-child) {
          border-right: 1px solid var(--banner-border-color);
        }
    
        &.approved {
          color: var(--approved-text-color);
        }
    
        &.billed {
          color: var(--status-active);
        }
        
        &.isZero{
          opacity: 0.4;
        }

        font-size: 0.77rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      &.separator:last-child {
        display: none;
      }
    }
  }
}