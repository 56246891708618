@import "src/themes/colors";

.actionIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.95rem;
  align-self: stretch;
  border-radius: 50%;
  padding: 0.6rem;
  cursor: pointer;

  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: var(--font-foreground);
      transition-property: fill;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 100ms;
    }
  }

  &:hover {
    background: var(--form-element-border-color);
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.active {
    background: var(--form-element-border-color);
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.small {
    padding: 4px;
    border-radius: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.round {
    border-radius: 20px;
  }
  &.normalBackground {
    background: var(--banner-border-color);
  }
}

.iconText {
  flex: 1 0 0;
}

.popper {
  margin-top: 0.43rem !important;
  border-radius: 0.43rem !important;
  z-index: 50;
}

.paper {
  padding: 0 !important;
  border-radius: 0.43rem !important;
  background: var(--secondary) !important;
}

.calendarContainer {
  display: flex;
  flex-direction: row;
  height: 100%;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px 0 16px;

    h6 {
      align-self: flex-start;
      margin-bottom: 0.85rem;
    }
  }

  .separatorLine {
    margin-block: 0.43rem 0.85rem;
    height: auto;
    width: 1px;
    background-color: var(--banner-border-color);
  }
}

.calendarPresetAction {
  border: 1px solid transparent !important;
  &.active {
    border: 1px solid #565555 !important;
    color: var(--color-main) !important
  }
}