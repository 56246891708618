@import "../../../../../themes/colors";

.container {
  display: flex;
  gap: 0.95rem;
  align-items: center;
}

.iconButton {
  padding: 0 !important;
  width: auto !important;
  box-shadow: none !important;
  svg {
    path {
      fill: #FFF;
    }
  }
  &:hover {
    background-color: var(--banner-border-color) !important;
  }
}

.selector {
  display: flex;
  align-items: center;
  gap: 0.95rem;
  text-align: center;
}

.labelWrapper {
  display: flex;
  gap: 0.95rem;

  .labelYear {
    color: var(--p-muted-color);
  }

  .activeText {
    color: #FFF;
  }

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.dash {
  align-self: center;
  text-align: center;
  color: var(--p-muted-color);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
