@import "../../../../../themes/colors";

.letterLimit {
  color: #565555;
  font-size: 0.75rem;
  margin-bottom: var(--spacing);
}

.error {
  font-size: 0.75rem;
  color: #F26D6B;
  margin-bottom: var(--spacing);
}

span.optional {
  color: var(--p-muted-color)
}

label.wrapper {
  --border-color: var(--font-foreground);
  margin: 0 0 24px 0 !important;
  input {
    margin: 8px 0 0 0 !important;
    box-shadow: none;

    input:active,
    input:focus {
      --border-color: var(--font-foreground);
    }
  }

  &.stretch {
    width: 100%;
  }
  &.auto {
    width: auto;
  }
}

textarea.verticalResize {
  resize: vertical;
}

textarea.horizontalResize {
  resize: horizontal;
}