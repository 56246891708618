@import "../../../themes/colors.scss";

.container {
    height: auto;
    background-color: var(--card-background-color);
    flex: 1 1 0;
    flex-flow: row;
}

.scheduleSelector {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px;
}

.header {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    height: auto;
    width: 100%;
    padding: 21px 24px;
    resize: horizontal;
    align-items: center;
    align-self: stretch;
    text-align: center;

    .headerItem:first-child{
        justify-self: flex-start;
    }

    .headerItem:last-child{
        justify-self: flex-end;
    }
}

.subheader {
    height: auto;
    display: flex;
    width: 100%;
    align-items: stretch;
    align-content: center;
    justify-content: space-between;
    padding: 0 18px 16px 16px;
    gap: 0.7rem;
    
    .searchFilter {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .info {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 8px;

        button {
            .icon {
                width: 2rem;
                height: 2rem;
                path {
                    fill: var(--schedule-action-button-color);
                }
            }
        }
    }
}

.addShift {
    display: flex !important;
    width: fit-content !important;
    min-width: auto !important;
    padding: 0.75rem 16px !important;
    align-items: center !important;
    justify-content: center !important;
    .icon {
        path {
            fill: #FFF;
        }
    }
}

.filter {
    display: flex;
    gap: 8px;
    flex-grow: 1;
}

.actionButton {
    display: flex !important;
    width: fit-content !important;
    min-width: auto !important;
    padding: 0.6rem 0.6rem !important;

    justify-content: center !important;
    align-items: center !important;

    font-size: 1.0125rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important;
}

.fill {
    path {
        fill: var(--text-menu)
    }
}

.input {
    min-height: 0;
    height: fit-content !important;
    width: 100%;
    margin: 0 !important;
    padding: 11px 8px 11px 16px !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  
    &:focus {
      box-shadow: none;
      border-color: var(--font-foreground)
    }
}