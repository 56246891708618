@import "../../../themes/colors";
.table {
  display: table;
}

.topBorder {
  display: flex;
  width: 100%;
  height: 2px;
  background: var(--banner-border-color);
}

.container {
  position: relative;
  display: contents;
  overflow: hidden;
  height: 100%;
  
  .body {
    justify-content: stretch;
    height: 100%;
    overflow-y: scroll;
  }
}

.baseCell {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: auto;
  align-items: flex-start;
  align-self: stretch;

  border-bottom: 1px solid var(--banner-border-color);

  &:not(:last-child) {
    border-right: 1px solid var(--banner-border-color);
  }
}

.cell {
  @extend .baseCell;
  display: inline-block;
  flex-direction: column;
  padding: 8px 16px;

  min-width: unset;
  max-width: unset;
  overflow: hidden;

  &.muted {
    color: var(--p-muted-color);
  }

  &.editable {
    &:hover {
      background: var(--card-background-color);
      & > .dropdownCell {
        svg {
          opacity: 1;
        }
      }
      & > .value {
        color: var(--color-main);
      }
    }
    cursor: pointer;
    &.text {
      cursor: text !important;     
    }
  }

  &.setBackground {
    background: var(--card-sectionning-background-color);
  }

  &.active {
    padding: 0;
    background: var(--card-background-color);
  }

  &.end {
    align-items: flex-end !important;
  }

  &.alignCenter {
    align-content: center;
  }

  &.alignBottom {
    align-content: flex-end;
  }

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.row {
  display: contents;
  justify-content: stretch;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  
  .rowContainer {
    width: 100%;
    display: contents;
    border: 1px solid transparent;

    .cell {
      height: 57px;
      overflow-y: auto;
    }

    &.active {
      border-color: var(--font-foreground);
      .cell {
        &.active {
          overflow-y: hidden;
        }
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--banner-border-color);
  }

  &.separator:before{
    grid-column: 1/ -1;
    content: "";
    background: var(--banner-border-color);
    height: 5px;
  }

  &.separator:last-child {
    display: none;
  }
}

.header {
  @extend .row;
  border-bottom: 1px solid var(--banner-border-color) !important;
  padding-right: 4px;
  display: flex;

  .headerCell {
    @extend .baseCell;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    color: var(--grid-text-color);
    font-feature-settings: 'clig' off, 'liga' off;

    &.flex {
      flex: 1 0 0;
    }
  }

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.dropdownCell {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  svg {
    opacity: 0;
    path {
      fill: #FFFFFF;
    }
  }
  .editable {
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  :last-child {
    justify-self: flex-end;
  }
}

.cellText {
  height: 100%;
  display: flex;
  white-space: break-spaces;

  .placeholder {
    display: none;
    opacity: 0;
    &.active {
      display: flex;
    }
    &.hover {
      opacity: 1;
    }
  }
}