@import "../../../../../themes/colors";
@import "../../../../../themes/breakpoints";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.row {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  &.day {
    cursor: pointer;
  }

  &.small {
    height: 40px;
    width: 40px;
  }

  &.header {
    display: flex;
  }

  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0;

  &.rangeSelected {
    border: 1px solid transparent;
    border-top: 1px solid var(--p-muted-color);
    border-bottom: 1px solid var(--p-muted-color);
    border-inline: 0;
    border-radius: 0;

    &.start {
      border-left: 1px solid var(--p-muted-color);
      border-radius: 8px 0 0 8px;
      background-color: var(--banner-border-color);
    }

    &.end {
      border-right: 1px solid var(--p-muted-color);
      border-radius: 0 8px 8px 0;
      background-color: var(--banner-border-color);
    }

    &.start.end {
      border: 1px solid var(--p-muted-color);
      border-radius: 8px;
    }
  }

  &.rangePreview {
    border: 1px dashed transparent;
    border-top: 1px dashed var(--p-muted-color);
    border-bottom: 1px dashed var(--p-muted-color);
    border-radius: 0;

    &.start {
      border-left: 1px dashed var(--p-muted-color);
      border-radius: 8px 0 0 8px;
      background-color: var(--banner-border-color);
    }

    &.end {
      border-right: 1px dashed var(--p-muted-color);
      border-radius: 0 8px 8px 0;
      background-color: var(--banner-border-color);
    }

    &.start.end {
      border: 1px dashed var(--p-muted-color);
      border-radius: 8px;
    }
  }

  &.rangeEnabled {

    &:hover {
      background-color: var(--banner-border-color);
    }
  }
}

.weekDay {
  color: var(--p-muted-color);
  font-size: 0.84rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}