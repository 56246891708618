@import "../../../../themes/breakpoints";
@import "../../../../themes/colors";

:root {
  --active-color: var(--text-menu);
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --text-color: var(--font-foreground);
  --active-border-color: var(--font-foreground);
  --list-box-color: var(--card-sectionning-background-color);
  --box-shadow: none;
  --border-radius: 8px;
}

.selector {
  position:relative;
  flex: 1 0 0;
  align-items: center;
  max-width: 300px;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.active :hover {
    .clearButton {
      visibility: visible;
    }
  }
}

.item {
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.no-shadow:focus {
  box-shadow: none;
}

.listBox{
  width: max-content;
  background: var(--list-box-color);
  margin: 8px 0 0;
  padding: 4px 0;
  position: absolute;
  list-style: none;

  overflow: auto;
  max-height: 14.7rem;
  border-radius: 8px;
  z-index: 1;

  .group {
    color: var(--p-muted-color);
    width: 100%;
    border-top: 1px solid var(--border-color);
    margin: 0;
    padding: 0;

    font-size: 0.65rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    //like header for group
    .header {
      width: 100%;
      display: flex;
      padding: 8px 16px 4px 16px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
    }
  }

  & li {
    color: var(--listbox-item-color);

    display: flex;
    width: 100%;
    padding: 14px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    align-self: stretch;
    margin: 0;

    font-size: 0.78rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    & span {
      flex-shrink: 0;
    }

    & svg {
      fill: #FFF;
      visibility: hidden;
    }
  }

  & li[aria-selected='true'] {
    color: #FFF;

    svg {
      visibility: visible;
    }
  }
  & li:hover {
    color: #FFF;
    background: var(--card-background-color);
  }
}

.wrapperActive {
  --border-color: var(--active-border-color)!important;
  --text-color: var(--active-color)!important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color)!important;
}

.wrapperFocus {
  @extend .wrapperActive;

  #dropdown-icon {
    transform: rotate(180deg);
  }
}

.selectorHeader {
  display: flex;
  justify-content: space-between;

  color: var(--font-foreground);

  button {
    margin: 0;
    padding: 0;
    min-width: 0;
    width: auto;
    background: none;
    border: none;
    text-decoration: none;
    box-shadow: none;

    color: var(--primary);

    &:hover {
      color: var(--primary-hover);
    }
    &:active {
      color: var(--primary-active);
    }

    font: inherit;
  }

  font-size: 0.70rem;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1.3 !important;
  margin-bottom: 0.3rem;
}

.inputWrapper{
  padding: 11px 8px 11px 16px;
  align-items: center;
  text-align: center;
  display: flex;
  width: auto;
  cursor: pointer;
  justify-content: space-between;

  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  background: var(--background-color);

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.focused {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  input {
    &:focus {
      box-shadow:none;
    };
    color: var(--text-color);
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    border-radius: 0;
    height: fit-content;
    flex: fit-content;
    width: 100%;
    text-align: start;

    font: inherit;
  }

  & svg {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    path {
      fill: var(--text-color);
    }
  }

  div.right {
    display: flex;
    .popperIcon {
      line-height: 100%;
      font-size: 12px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .clearButton {
      padding: 0 !important;
      width: unset;
      margin: 0;
      height: unset;
      border: none;
      font-size: 12px;
      line-height: 100%;
      background: none;
      visibility: hidden;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--p-muted-color);
        }
      }

      &:hover {
        svg {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }
  }

  font-size: 0.65rem;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1.3 !important;
}
.tagsWrapper{
  flex-wrap: wrap;
  display: none;
  align-items: center;
  gap: 0.4rem;
  &.active {
    display: flex
  }

  font-size: 0.8rem;
  color: var(--active-color);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

input::placeholder {
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
