@import "../../../../../themes/colors";

.container {
  display: flex;
  width: fit-content;

  &.fullSize {
    width: 100%;
  }
}

.selector {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &.stretch {
    justify-content: space-between;
  }
}

.switchButton {
  background: none !important;
  padding: 0 !important;
  width: auto !important;

  svg {
    path {
      fill: #FFFFFF;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.small {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.month {
  &.flex {
    width: 100%;
  }
}

.monthsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 385px - 24px);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;

  &.flex {
    display: flex;
    grid-template-columns: none;
    flex-wrap: wrap;
  }
}

.monthWrapper {
  display: flex;
  padding: 8px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--secondary);
  width: fit-content;
  &.flex {
    flex: 1;
  }
  &.wrap {
    flex-direction: row;
  }
}

.name {
  margin: 0;
  color: var(--color-main);
  &.small {
    font-size: 0.8rem;
  }

  font: inherit;
}

.separatorLine {
  height: 100%;
  width: 1px;
  background-color: var(--banner-border-color);
}

.secondView {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: 0.5rem;
}