@import "src/themes/colors";

:root {
  --mate-color : var(--status-approved-blured);
}

.progress {
  display: flex;
  flex-direction: row;
  width: 100%;
  visibility: hidden;
  border-radius: 8px;
  height: 4px;
  gap: 3px;
  justify-content: stretch;
  &.approve {
    .soul {
      background: var(--approved-text-color);
    }
    .mate {
      span {
        --mate-color: var(--status-approved-blured);
      }
    }
  }
  &.billable {
    .soul {
      background: var(--status-active);
    }
    .mate {
      span {
        --mate-color: var(--status-active-blured);
      }
    }
  }

  .soul {
    display: block;
    float: left;
    border-radius: 8px 0 0 8px;
    &.full {
      border-radius: 8px;
    }
  }
  .mate {
    display: flex;
    flex: 1;
    border-radius: 0 8px 8px 0;
    &.full {
      display: none;
    }
    span {
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background-color: var(--mate-color);
    }
  }
  &.active {
    visibility: visible;
  }
}