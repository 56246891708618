:root {
    --color-main: #FFFFFF;

    --bg-main: #FFF;

    --bg-menu: #1d1d1d;

    --hover-menu: #323232;

    --text-menu: #FFFFFF;

    --tab-text-color: #828282;
    --tab-active-text-color: #333;
    --tab-indicator-color: #333;

    --table-header-color: #828282;

    --overviewBlock-row2-bg: #F8F8F8;
    --overviewBlock-cell-title: #828282;
    --overviewBlock-cell-value: #333;

    --overviewBlock-input-border: #E0E0E0;

    --catalog-background: #F2F2F2;

    //pico-overrides
    --primary: #F26D6B;
    --secondary: #1F1F1F;
    --h3-color: #FFFFFF;
    --h2-color: #FFFFFF;
    --h1-color: #FFFFFF;
    --card-sectionning-background-color: #1F1F1F;
    --card-background-color: #181818;
    --color: #FFFFFF;
    --form-element-background-color: #121212;
    --form-element-border-color: #2C2B2B;
    --modal-overlay-background-color: rgba(18, 18, 18, 0.5);

    --status-active: rgb(89, 202, 89);
    --status-waiting: #ffcc00;
    --primary-background: #662E2D;

    --active-background: #255525;

    --p-muted-color: #565555;
    
    --subscription-banner-background-color: #6343E4;

    --banner-border-color: #282727;

    --font-foreground: #727171;

    --approved-text-color: #f5cd47;

    --grid-text-color: #9E9D9D;

    --day-icon-info-color: #255525;
    --day-icon-info-hover-color: #3F8F3F;
    --day-icon-warning-color: var(--primary-background);
    --day-icon-warning-hover-color: #AC4D4C;

    --listbox-item-color: #a6a6a6;
    --schedule-action-button-color: #BEBDBD;

    --status-active-blured: rgba(89, 202, 89, 0.18);
    --status-approved-blured: rgba(245, 205, 71, 0.18)
}

//pico-overrides
h3 {
    --typography-spacing-vertical: 1.5rem;
}

[role="button"] {
    line-height: 1.2;
}

$bg-main: #121212;
$color-main: #FFFFFF;
$red-spark: #F26D6B;
