body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

dialog article {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  dialog {
    width: 100%;
    padding: 0;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
  }
  dialog article {
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
