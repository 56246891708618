@import "../../../../../themes/colors";

.dropdown {
  margin: 0 !important;
  border-radius: 8px;
  background-color: var(--form-element-background-color);
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  box-shadow: none;

  :required {
    border-color: #727171;
  }
  option {
    color: var(--color-main)
  }

  &::before,
  &::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 1rem;
    pointer-events: none;
  }

  &::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
  }

  &::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
  }
}

span.optional {
  color: var(--p-muted-color)
}

div.wrapper {
  width: 100%;
  margin-bottom: 24px;
  label {
    margin-bottom: 8px;
  }
}