@import "../../../../themes/colors";
@import "../../../../themes/breakpoints";

.banner {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  background: var(--subscription-banner-background-color);
  font-family: "Roboto", sans-serif;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    h5 {
      color: var(--color-main);
      margin: 0;
      font-weight: inherit;
    }
  
    p {
      font-weight: inherit;
      margin: 0;
    }
  
    a {
      color: var(--color-main);
      cursor: pointer;
    }
  
    button {
      margin-top: 1rem;
    }
  
    @include more-1000 {
      svg {
        width: 36px;
        height: 36px;
      }
      h5 {
        font-size: 1.2em;
      }
  
      p {
        font-size: 15px;
      }
      a {
        font-size: 15px;
      }
    }
  
    @include less-1000 {
      margin: 0;
      border-radius: 0;
  
      h5 {
        font-size: 1.2em;
      }
  
      p {
        font-size: 14px;
      }
  
      a {
        font-size: 14px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @include more-1000{
    font-weight: 500;
    border-radius: 0.4rem;
  }

  @include less-1000 {
    margin: 0;
    border-radius: 0;
  }
}