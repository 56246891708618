@import "../../../themes/colors";

.menu {
  display: flex;
  height: 100%;
  align-items: center;
}

.actionIcon {
  display: flex;
  padding: 0.5rem 0.6rem !important;
  height: 100% !important;
  width: auto !important;
  min-width: 0 !important;
  justify-content: center;
  align-items: center;
  gap: 8px !important;
  border-radius: 8px !important;
  background: var(--banner-border-color) !important;
  box-shadow: none !important;
  min-height: 0 !important;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--font-foreground);
      transition-property: fill;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 100ms;
    }
  }

  &:hover {
    background: var(--form-element-border-color) !important;

    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.active {
    background: var(--form-element-border-color) !important;

    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.round {
    border-radius: 20px !important;
    background-color: transparent !important;
    padding: 0.4rem !important;
    height: fit-content !important;

    &.active, &:hover {
      background: var(--banner-border-color) !important;

      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
  }

}
.smallActionIcon {
  @extend .actionIcon;
  padding: 4px !important;
  border-radius: 4px !important;
  min-height: 35px !important;
svg {
  width: 16px;
  height: 16px;
}
}

.actionMenu {
  background: var(--secondary) !important;
  border-radius: 8px;
  color: var(--listbox-item-color);
}

.actionItem {
  text-transform: none;
  display: flex !important;
  padding: 14px 16px !important;
  align-items: center !important;
  gap: 12px !important;
  align-self: stretch !important;
  flex: 1 0 0;

  color: var(--listbox-item-color);

  font-size: 0.7875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  border-radius: 0.421rem;

  svg {
    width: 20px !important;
    height: 20px !important;

    path {
      fill: var(--listbox-item-color);
    }
  }

  &:hover {
    background: var(--card-background-color) !important;
    color: #FFFFFF;

    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.iconText {
  flex: 1 0 0;
}

.popper {
  border-radius: 0.421rem !important;
  z-index: 50;
}

.paper {
  border-radius: 0.421rem !important;
  background: var(--secondary) !important;
}