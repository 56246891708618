@import "../../../../../themes/colors";

.text {
  margin: 0 !important;
  padding: 8px 16px !important;
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
  border-radius: 0 !important;
  background-color: var(--card-background-color);

  text-align: start;
  text-wrap: pretty;
  white-space: break-spaces;

  font: inherit !important;

  &::selection {
    background: var(--p-muted-color);
  }
}

.skeletonWrapper {
  height: 50px !important;
  width: 155px;
  padding: 8px 14px !important;
}

.date {
  padding: 8px 16px;
}