@import "../../../themes/breakpoints";

dialog {
  align-items: start;
  article {
    max-width: 45em;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    padding: 0;
    header {
      margin: 0;
      padding: 1.7rem 1.55rem;
    }
    footer {
      margin: 0;
      padding: 1.7rem 1.55rem;
    }
  }
}

.content {
  height: 100%;
  overflow-y: auto;
  @include more-1000 {
    padding: 2.1rem 1.7rem;
  }
  @include less-1000 {
    padding: 12px 24px;
  }
}