@import "../../../themes/colors";
@import "../../../themes/breakpoints";

:root {
  --border-color: var(--form-element-border-color);
  --regular-text-color: var(--font-foreground);
  --billable-text-color: var(--status-active);
  --border-radius: 0.421rem;
}

.total {
  display: grid;
  grid-template-columns: minmax(15ch, auto) minmax(12ch, auto) minmax(120px, 1fr) minmax(120px, 1fr);
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &.twoCol {
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
  }

  .totalPart {
    display: flex;
    padding: 0.5rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    height: 100%;
    width: auto;
    position: relative;
   
    p {
      color: var(--regular-text-color);
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: inherit;
    }

    .totalApproved {
      color: var(--approved-text-color)
    }

    .totalBillable {
      color: var(--status-active)
    }

    & {
      border: 1px solid var(--border-color);
    }
    &:not(:first-child) {
      border-left: unset;
    }
    &:first-child {
      border-top-left-radius: 0.421rem;
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .inactive {
    & > * {
      opacity: 0.4;
    }
  }

  .hide {
    & > * {
      opacity: 0 !important;
    }
  }

  font-size: 0.65rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include less-1000 {
    flex-direction: column;
    gap: 0;
  }
}

.indicator {
  display: flex;
  position: absolute;
  width: 85%;
  top: 80%;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}