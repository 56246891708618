@import "../../../../themes/colors";
@import "../../../../themes/breakpoints";

.wrapper {
  width: auto;
  @media screen and (max-width: $content-tablet-width) {
    width: 100%;
  }
}

.iconInfo {
  align-self: center;
  padding: 8px;
  svg {
    path {
      fill: var(--primary);
    }
  }
}

.paper {
  padding: 8px 16px !important;
  color: var(--primary);
  box-shadow: none !important;
  background-color: var(--primary-background) !important;

  .text {
    margin: 0;
    color: var(--primary);
    white-space: pre-line;
  }
}

