@import "src/themes/colors";

.labelWrapper {
  display: flex;
  gap: 8px;

  align-content: center;

  .labelYear {
    color: var(--p-muted-color);
  }

  .activeText {
    color: #FFF;
  }

  &.control {
    font-size: 1rem !important;
  }

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}