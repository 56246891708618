@import "../../../../themes/colors.scss";
@import "../../../../themes/breakpoints";

$bg-hover-menu: #1F1F1F;
$active-text-color: #FFFFFF;
$menu-text-color: #565555;

.container {
  width: 100%;
  cursor: pointer;

  .menuItem {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--listbox-item-color);
    align-items: center;
    gap: 16px;
    
    @include more-1000 {
      border-radius: 8px;
    }
    @include less-1000 {
      border-radius: 0;
    }

    &:hover {
      background-color: $bg-hover-menu;
      cursor: pointer;
      color: --listbox-item-color;
  
      svg {
        path {
          fill: var(--listbox-item-color)
        }
      }
  
      @include more-1000 {
        border-radius: 8px;
      }
      @include less-1000 {
        border-radius: 0;
      }
    
      /*&:focus {
        --background-color: var(--card-background-color);
      }*/
    }
  }

  .childMenuItem {
    font-size: 15.75px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--listbox-item-color);
    align-items: center;
    padding-left: 65px;
    gap: 16px;
    padding: 12px 12px 12px 65px;
    align-items: flex-start;
    gap: 0.5rem;


    @include more-1000 {
      border-radius: 8px;
    }
    @include less-1000 {
      border-radius: 0;
    }
  
    &:hover {
      background-color: $bg-hover-menu;
      cursor: pointer;
      color: --listbox-item-color;
  
      svg {
        path {
          fill: var(--listbox-item-color)
        }
      }
  
      @include more-1000 {
        border-radius: 8px;
      }
      @include less-1000 {
        border-radius: 0;
      }
    }
  }
  
  .svg {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 0.5rem;

    path {
      transition: fill .1s linear;
      fill: $menu-text-color
    }
  }

  .text {
    font-size: 0.85em;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .active {
    background-color: $bg-hover-menu;
    cursor: pointer;
    color: $active-text-color;
  
    &:hover {
      background-color: $bg-hover-menu;
    }
  
    svg {
      path {
        fill: $active-text-color;
      }
    }
  
    @include more-1000 {
      border-radius: 8px;
    }
    @include less-1000 {
      border-radius: 0;
    }
  }
}