@import "src/themes/colors";

:root {
  --paper-background-color: var(--secondary);
  --focused-background-color: var(--form-element-border-color);
  --focused-color: var(--color-main);
  --default-svg-color: var(--font-foreground);
  --active-svg-color: var(--color-main);
  --filter-count-background-color: var(--card-background-color);
  --filter-count-border-color: var(--secondary);
  --filter-count-color: var(--color-main);
  --filter-header-background-color: var(--secondary);
  --filter-footer-background-color: var(--secondary);
  --filters-background-color: var(--card-background-color);
  --filter-paper-background-color: var(--secondary);
  --filter-reset-link-color: var(--schedule-action-button-color);
  --filter-view-separator-background-color: var(--font-foreground);
  --filter-view-color: var(--font-foreground);

  $popoverBackgroundColor: var(--filter-paper-background-color)
}

.filterButton {
  width: fit-content !important;
  min-width: 100px !important;
  justify-content: center;
  gap: 8px;

  font-size: 12px;

  svg {
    fill: var(--default-svg-color);
  }

  &.active {
    svg {
      fill: var(--active-svg-color);
    }
  }

  &.focused {
    background: var(--focused-background-color);
    color: var(--focused-color);
  }

  .filterCount {
    background: var(--filter-count-background-color);
    border: 1px solid var(--filter-count-border-color);
    padding: 2px 6px;
    border-radius: 4px;
    color: var(--filter-count-color);

    font-weight: 600;
  }
}

.filterPopover {
  margin-top: 4px !important;
  .MuiPopover-paper {
    border-radius: 8px !important;
    min-width: 300px !important;
    flex-direction: column !important;
    background: var(--filter-paper-background-color) !important;
  }
}

.filterHeader {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background: var(--filter-header-background-color);

  //cancel-button
  button {
    min-width: 0 !important;
    width: fit-content !important;
    padding: 0 !important;
    box-shadow: none !important;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-main);
      }
    }
  }

  //title
  color: var(--focused-color);
  font-size: 0.9rem;
}

.filtersWrapper {
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background: var(--filters-background-color);
}

.filterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: var(--filter-footer-background-color);

  //apply filter
  button {
    min-width: 0 !important;
    width: fit-content !important;
  }
}

// popup for preview selected filters
.filtersView {
  background: var(--filters-background-color);
  color: var(--filter-view-color);
  .filtersSeparator {
    background: var(--filter-view-separator-background-color);
    height: 1px;
    width: 100%;
  }
  font-size: 0.7rem;
}

:export {
  linkColor: var(--schedule-action-button-color);
  paperBackgroundColor: var(--filter-paper-background-color);
}