$content-tablet-width: 1000px;
$content-air-width: 1440px;
$content-full-width: 1800px;
$content-large-width: 2450px;
$iphone-x-width: 375px;
$iphone-se-width: 320px;
$iphone-12-width: 390px;

@mixin for-small-phone {
  @media screen and (max-width: $iphone-x-width) {
    @content;
  }
}

@mixin for-phone {
  @media screen and (max-width: $iphone-12-width) {
    @content;
  }
}

@mixin less-1000 {
  @media screen and (max-width: $content-tablet-width) {
    @content;
  }
}

@mixin more-1000 {
  @media screen and (min-width: $content-tablet-width) {
    @content;
  }
}

@mixin height-less-800 {
  @media screen and (max-height: 800px) {
    @content;
  }
}

@mixin largest {
  @media screen and (min-width: $content-large-width) {
    @content;
  }
}
