@import "../../../themes/colors.scss";
@import "../timesheet-shared-tool/timesheet-shared-tool";

.container {
  display: flex;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  gap: 8px;
  height: fit-content;
  width: 140px;
  background: var(--secondary);
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;

  &.wrap {
    justify-content: center;
  }
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  & > .hours {
    font-size: calc(100% / 1.3);
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 23.625px */
  }
  & > .content:not(:first-child) {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
  }
  & > .content:last-child {
    color: var(--p-muted-color)
  }

  & > .content {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.wrap {
    justify-content: center;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  &.wrap {
    flex-direction: row;
    :last-child {
      order: -1;
    }
  }
}
