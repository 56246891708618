@import "../../../../../../themes/colors";
@import "../../../../../../themes/breakpoints";

$less-mac: 1400px;
$less-wide: 1800px;

@mixin less-mac {
  @media screen and (max-width: $less-mac) {
    @content;
  }
}

@mixin default {
  @media screen and (min-width: $less-wide) {
    @content;
  }
}

.calendar {
  justify-content: space-between;
  @include less-mac {
    justify-content: space-around;
  }
}

.dropdownDescription {
  margin: 0;
  font-weight: 400;
  color: var(--p-muted-color);
}

.monthWrapper {
  background: var(--card-background-color);
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.4rem 1rem 0.8rem;

  .name {
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }
}

.action {
  padding: 0.5rem !important;
  min-width: 0 !important;
  height: 100% !important;

  .icon {
    width: 20px;
    height: 20px;
    path {
      fill: var(--schedule-action-button-color)
    }
  }
}

.actionButton {
  padding: 0.9rem 0.8rem !important;
  min-height: 0 !important;
  //height: 100% !important;

  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important;

  .icon {
    width: 24px;
    height: 24px;
    path {
      fill: var(--schedule-action-button-color)
    }
  }
}