@import "../../themes/colors.scss";
@import "../../themes/breakpoints.scss";

$bg-menu: #181818;

.desktopMenu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  position:sticky;
  top: 0;
  min-height: 100vh;
  height: 100%;
  padding: 1.2rem;
  background-color: var(--card-background-color, #181818);
  overflow: hidden;
  border-right: 1px solid var(--banner-border-color, #282727);
  align-items: flex-start;
  flex-shrink: 0;
  gap: 8px;

  @include less-1000 {
    display: none;
  }

  @include largest {
    position: absolute;
    height: auto;
    bottom: 1rem;
    top: 1rem;
    left: 1rem;
    min-height: unset;
    border: none;
    border-radius: 0.5rem;
  }
  
  &.open {
    .menuWrapper {
      width: auto;
      align-items: flex-start;
    }
  }

  .menuWrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    overflow-y: scroll;
  }
}

.mobileMenu {
  position:sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  min-height: 64px;
  background: $bg-menu;

  @include more-1000 {
    display: none;
  }
}

.opened {
  width: 100%;
  height: 100%;
  background: $bg-menu;
  position: fixed;
  top: 64px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .openedMenuWrapper {
    width: inherit;
    overflow-y: auto;
  }

  .openedSubscriptionWrapper {
    margin-bottom: 4rem;

  }
}

.menuItems {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  overflow: auto;
}

.logo {
  cursor: pointer;
  align-self: flex-start;
  margin: 20px 0 20px 16px;
}

.subscriptionBanner {
  width: 100%;
}

.menuBottom {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
  .banner {
    @include height-less-800 {
      display: flow;
      position: absolute;
      width: 82%;
      right: 95%;
      bottom: 2%;
      transition: right ease 0.3s;
      &:hover {
        right: 12%;
      }
    }
  }
}
