@import "../../../../themes/colors";

.container {
    height: auto;
    background-color: var(--card-background-color);
    flex: 1 1 0;
    flex-flow: row;
}

.header {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    height: auto;
    width: 100%;
    padding: 21px 24px;
    resize: horizontal;
    align-items: center;
    align-self: stretch;
    text-align: center;

    h3 {
        margin: 0;
    }

    .scheduleSelector {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 16px;
    }

    .headerItem:first-child{
        justify-self: flex-start;
    }

    .headerItem:last-child{
        justify-self: flex-end;
    }
}

.subheader {
    height: auto;
    display: flex;
    width: 100%;
    align-items: stretch;
    align-content: center;
    justify-content: space-between;
    padding: 0 24px 16px 24px;
    gap: 0.7rem;

    .searchFilter {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .info {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 8px;
    }
}

.input {
    min-height: 0;
    height: fit-content !important;
    width: 100%;
    margin: 0 !important;
    padding: 11px 8px 11px 16px !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  
    &:focus {
      box-shadow: none;
      border-color: var(--font-foreground)
    }
}