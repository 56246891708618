@import "../../../themes/breakpoints.scss";

.pageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1.25rem 5.5rem;

    @include less-1000 {
      padding: 1rem 1.5rem;
    }

    @include largest {
      margin: 0 auto;
      width: 1920px;
    }
  }
}
