@import "../../../../themes/breakpoints";
@import "../../../../themes/colors";

.createPaymentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
    padding: 3.2rem 0 3.2rem 0;
    border: 1px solid var(--banner-border-color);
    border-radius: 8px;
}

.paymentMethod {
    display: flex;
    justify-content: space-between;
    background: var(--card-background-color);
    border: none;
    border-radius: 8px;

    .nameWrapper {
        display: flex;
        align-items: center;
        padding: 1.2rem;
    }

    .actionIconWrapper {
        padding: 1.2rem;
    }
}

.methodIcon {
    width: 62px;
    height: 41px
}

.methodBody {
    text-align: center;
}

.actionButton {
    width: unset !important;
    align-self: center;
}