.state {
  display: flex;
  padding: 4px !important;
  height: 35px !important;
  width: auto !important;
  min-width: 0 !important;
  justify-content: center;
  align-items: center;
  gap: 8px !important;
  border-radius: 4px !important;
  background: var(--banner-border-color) !important;
  box-shadow: none !important;

  &.approved {
    background: var(--approved-text-color) !important;
    & > * {
      color: var(--form-element-background-color);
      path {
        fill: var(--form-element-background-color);
      }
    }
  }
  &.billable {
    background: var(--status-active) !important;
    & > * {
      color: var(--form-element-background-color);
      path {
        fill: var(--form-element-background-color);
      }
    }
  }

  &:hover {
    & > svg {
      path {
        fill: #FFF
      }
    }
  }
  &:disabled {
    opacity: 1;
  }
}