@import "../../../themes/colors.scss";

.icon {
    width: 16px !important;
    height: 16px !important;
    color: var(--font-foreground);
    path {
      fill: var(--form-element-background-color)
    }
  
    &.normal {
      path {
        fill: var(--grid-text-color)
      }
    }
  }