
.timeShiftCell {
  height: 100%;
  padding: 4px 16px 4px 8px;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.gridWrapper {
  height: auto;
  overflow-y: auto;
}