@import "../../../../themes/colors";

.cell {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  text-align: right;

  font-size: 0.77rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &.approved {
    color: var(--approved-text-color);
  }

  &.billed {
    color: var(--status-active);
  }
  
  &.isZero{
    opacity: 0.4;
  }
}