@import "../../../themes/breakpoints.scss";

.pageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .content {
    overflow: hidden;
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;

    @include largest {
      margin: 0 auto;
      width: 1920px;
    }
  }
}
