@import "../../../themes/colors.scss";
@import "../timesheet-shared-tool/timesheet-shared-tool";

.container {
  display: flex;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  gap: 8px;
  width: 140px;
  background: var(--secondary);
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  justify-content: end;

  &.small {
    width: auto;
    padding: 0.5rem 1rem;
  }

}

.menu {
  display: flex;
  flex-direction: row;
  justify-items: end;
  gap: 4px;
  align-self: stretch;

  :last-child {
    order: -1;
  }
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;

  font-size: calc(100% / 1.3);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 23.625px */
}