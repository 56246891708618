@import "src/themes/colors";

span.optional {
  color: var(--p-muted-color)
}

.error {
  font-size: 0.75rem;
  color: #F26D6B;
  margin-bottom: var(--spacing);
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;

  &.stretch {
    width: 100%;
  }
}